<script>
import axios from "axios";
import { getGraduationList } from "@/helpers/get-graduation-list";
import MemberEditTemplate from "../templates/MemberEditTemplate.vue";

export default {
  components: { MemberEditTemplate },
  name: "MemberEditPage",
  data: () => ({
    addressQuery: {
      street: "",
      complement: "",
      neighborhood: "",
      city: "",
      state: "",
    },
    graduationList: [],
    step: 1,
    form: {},
    dialog: {
      birthday: false,
      paymentReference: false,
      paymentDate: false,
      registerDate: false,
    },
    show: true,
    formMode: "new",
  }),
  props: {
    id: {
      type: String,
      default: "",
    },
  },
  created() {
    this.addressQuery = {
      street: "",
      complement: "",
      neighborhood: "",
      city: "",
      state: "",
    };
  },
  mounted() {
    this.graduationList = getGraduationList(this.genre);
  },
  computed: {
    member() {
      return this.$store.state.user.users.filter(
        (member) => member.id === this.id
      )[0];
    },
    academyId() {
      return this.$store.state.academy.currentAcademy.id;
    },
    genre: function () {
      return this.$store.state.academy.currentAcademy.genre;
    },
  },
  methods: {
    // TODO: Move to service
    // Call from WHERE?
    // How to feed this info?
    async checkCEP(payload) {
      let pureCep = payload.replaceAll("-", "");
      if (pureCep.length == 8) {
        /* this.$store.dispatch("loading", true); */
        await axios
          .get("https://viacep.com.br/ws/" + pureCep + "/json/")
          .then(async (response) => {
            if (response != undefined) {
              this.addressQuery.street = response.data.logradouro;
              this.addressQuery.complement = response.data.complemento;
              this.addressQuery.neighborhood = response.data.bairro;
              this.addressQuery.city = response.data.localidade;
              this.addressQuery.state = response.data.uf;
              this.addressQuery.cep = response.data.cep;
            }
          });
        /* this.$store.dispatch("loading", false); */
      }
    },
    async submit(payload) {
      let academy = {
        isActive: payload.academy.isActive,
        register: payload.academy.registerDate,
      };

      let editMember = {
        name: payload.basic.name,
        graduation: payload.academy.graduation,
        contact: {
          phone: payload.basic.phone,
          email: payload.basic.email,
        },
        personal: {
          birthday: payload.personal.birthday,
          rg: payload.personal.rg,
          rgOrgan: payload.personal.rgOrgan,
          cpf: payload.personal.cpf,
          nameMother: payload.personal.nameMother,
          nameFather: payload.personal.nameFather,
          nationality: payload.personal.nationality,
        },
        work: {
          name: payload.work.name,
          phone: payload.work.phone,
        },
        address: {
          street: payload.address.street,
          complement: payload.address.complement,
          number: payload.address.number,
          neighborhood: payload.address.neighborhood,
          cep: payload.address.cep,
          city: payload.address.city,
          state: payload.address.state,
        },
        academies: {},
      };

      if (payload.work.scolarship != undefined) {
        editMember.work.scolarship = payload.work.scolarship;
      }

      if (payload.personal.gender != undefined) {
        editMember.personal.gender = payload.personal.gender;
      }

      editMember.academies[this.academyId] = academy;

      await this.$store.dispatch("user/update", {
        id: this.id,
        member: editMember,
      });
    },

    cancelNewMember() {
      this.$router.push("/");
    },
  },
};
</script>

<template>
  <member-edit-template
    :member="member"
    :academyId="academyId"
    :addressQuery="addressQuery"
    @checkCEP="checkCEP"
    @submit="submit"
  ></member-edit-template>
</template>

<style lang="scss" scoped>
.container,
form {
  min-height: 100vh;
  min-width: 100%;
}
</style>
