import userGender from "@/constants/user-gender";
import userScolarship from "@/constants/user-scolarship";

const memberEditForm = {
  1: {
    1: {
      type: "title",
      title: "Informações Básicas",
    },
    2: {
      type: "subtitle",
      subtitle: "Teste de subtitulo",
    },
    3: {
      type: "form",
      name: "basic",
      form: {
        name: {
          value: "",
          required: true,
          label: "Nome Completo",
          name: "name",
          inputType: "text",
        },
        phone: {
          value: "",
          required: true,
          keyboardType: "tel",
          label: "Telefone",
          name: "phone",
          mask: ["(##) #####-####"],
          inputType: "text",
        },
        email: {
          value: "",
          required: true,
          keyboardType: "email",
          label: "E-mail",
          name: "email",
          inputType: "text",
        },
      },
    },
    4: {
      buttons: ["previous", "next", "submit"],
      type: "buttons",
    },
  },
  2: {
    1: {
      type: "title",
      title: "Informações da Academia",
    },

    2: {
      type: "form",
      name: "academy",

      form: {
        graduation: {
          value: "",
          label: "Graduação",
          name: "graduation",
          inputType: "select",
          items: [],
        },
        registerDate: {
          value: "",
          label: "Data de Registro",
          inputType: "date",
          name: "registerDate",
        },
        isActive: {
          value: true,
          inputType: "switch",
          label: "Membro Ativo?",
          name: "isActive",
        },
      },
    },
    3: {
      buttons: ["previous", "next", "submit"],
      type: "buttons",
    },
  },
  3: {
    1: {
      type: "title",
      title: "Informações Pessoais",
    },

    2: {
      type: "form",
      name: "personal",
      form: {
        birthday: {
          value: "",
          label: "Data de Nascimento",
          name: "birthday",
          inputType: "date",
        },
        gender: {
          value: "",
          label: "Gênero",
          name: "gender",
          inputType: "select",
          items: userGender,
        },
        rg: {
          value: "",
          keyboardType: "tel",
          label: "RG",
          mask: [
            "########-#",
            "#########-#",
            "##########-#",
            "###########-#",
            "############-#",
            "#############-#",
          ],
          name: "rg",
        },
        rgOrgan: {
          value: "",
          label: "Orgão Emissor",
          name: "rgOrgan",
        },
        cpf: {
          value: "",
          keyboardType: "tel",
          label: "CPF",
          mask: ["###.###.###-##"],
          name: "cpf",
        },
        nameMother: {
          value: "",
          label: "Nome da Mãe",
          name: "nameMother",
        },
        nameFather: {
          value: "",
          label: "Nome da Pai",
          name: "nameFather",
        },
        nationality: {
          value: "",
          label: "Nacionalidade",
          name: "nationality",
        },
      },
    },
    3: {
      buttons: ["previous", "next", "submit"],
      type: "buttons",
    },
  },
  4: {
    1: {
      type: "title",
      title: "Endereço",
    },

    2: {
      type: "form",
      name: "address",

      form: {
        cep: {
          value: "",
          keyboardType: "tel",
          label: "CEP",
          mask: ["#####-###"],
          name: "cep",
        },
        street: {
          value: "",
          label: "Logradouro (Rua, Avenida, Etc)",
          name: "street",
        },
        number: {
          value: "",
          keyboardType: "tel",
          label: "Número",
          name: "number",
        },
        complement: {
          value: "",
          label: "Complemento",
          name: "complement",
        },
        neighborhood: {
          value: "",
          label: "Bairro",
          name: "neighborhood",
        },
        city: {
          value: "",
          label: "Cidade",
          name: "city",
        },
        state: {
          value: "",
          label: "Estado",
          name: "state",
        },
      },
    },
    3: {
      buttons: ["previous", "next", "submit"],
      type: "buttons",
    },
  },
  5: {
    1: {
      type: "title",
      title: "Informações Profissionais",
    },

    2: {
      type: "form",
      name: "work",

      form: {
        scolarship: {
          value: "",
          label: "Grau de Escolaridade",
          name: "scolarship",
          inputType: "select",
          items: userScolarship,
        },
        name: {
          value: "",
          label: "Nome da Empresa",
          name: "name",
        },
        phone: {
          value: "",
          keyboardType: "tel",
          label: "Telefone da Empresa",
          mask: ["(##) ####-####", "(##) #####-####"],
          name: "phone",
        },
      },
    },
    3: {
      buttons: ["previous", "next", "submit"],
      type: "buttons",
    },
  },
};

export default memberEditForm;
