<script>
import AppStepper from "@/components/atoms/AppStepper.vue";
import AppDialog from "@/components/atoms/AppDialog.vue";
import TheInnerToolbar from "@/components/organisms/TheInnerToolbar.vue";
import memberEditForm from "@/constants/form/member-edit-form";
import { getGraduationList } from "@/helpers/get-graduation-list";

export default {
  name: "MemberEditTemplate",
  components: { AppStepper, AppDialog, TheInnerToolbar },
  methods: {
    submit(payload) {
      this.$emit("submit", payload);
    },
    cancelConfirm() {
      this.dialog = false;
      this.$router.back();
    },
    cancelCancel() {
      this.dialog = false;
    },
    cancel() {
      this.dialog = true;
    },
    populateForm(payload) {
      this.editMember.basic = {};
      this.editMember.academy = {};
      this.editMember.personal = {};
      this.editMember.work = {};
      this.editMember.address = {};

      this.editMember.basic.name =
        payload.name != undefined ? payload.name : "";
      this.editMember.academy.graduation =
        payload.graduation != undefined ? payload.graduation : "";

      this.editMember.basic.phone =
        payload.contact.phone != undefined ? payload.contact.phone : "";
      this.editMember.basic.email =
        payload.contact.email != undefined ? payload.contact.email : "";

      this.editMember.personal.birthday =
        payload.personal.birthday != undefined ? payload.personal.birthday : "";
      this.editMember.personal.gender =
        payload.personal.gender != undefined ? payload.personal.gender : "";
      this.editMember.personal.rg =
        payload.personal.rg != undefined ? payload.personal.rg : "";
      this.editMember.personal.rgOrgan =
        payload.personal.rgOrgan != undefined ? payload.personal.rgOrgan : "";
      this.editMember.personal.cpf =
        payload.personal.cpf != undefined ? payload.personal.cpf : "";
      this.editMember.personal.nameMother =
        payload.personal.nameMother != undefined
          ? payload.personal.nameMother
          : "";
      this.editMember.personal.nameFather =
        payload.personal.nameFather != undefined
          ? payload.personal.nameFather
          : "";
      this.editMember.personal.nationality =
        payload.personal.nationality != undefined
          ? payload.personal.nationality
          : "";

      this.editMember.work.scolarship =
        payload.work.scolarship != undefined ? payload.work.scolarship : "";
      this.editMember.work.name =
        payload.work.name != undefined ? payload.work.name : "";
      this.editMember.work.phone =
        payload.work.phone != undefined ? payload.work.phone : "";

      this.editMember.address.street =
        payload.address.street != undefined ? payload.address.street : "";
      this.editMember.address.complement =
        payload.address.complement != undefined
          ? payload.address.complement
          : "";
      this.editMember.address.number =
        payload.address.number != undefined ? payload.address.number : "";
      this.editMember.address.neighborhood =
        payload.address.neighborhood != undefined
          ? payload.address.neighborhood
          : "";
      this.editMember.address.cep =
        payload.address.cep != undefined ? payload.address.cep : "";
      this.editMember.address.city =
        payload.address.city != undefined ? payload.address.city : "";
      this.editMember.address.state =
        payload.address.state != undefined ? payload.address.state : "";

      this.editMember.academy.registerDate =
        payload.academies[this.academyId].register != undefined
          ? payload.academies[this.academyId].register
          : "";
      this.editMember.academy.isActive =
        payload.academies[this.academyId].isActive != undefined
          ? payload.academies[this.academyId].isActive
          : "";

      this.updateValues(this.editMember);
    },
    updateValues(payload) {
      let keys = Object.keys(this.stepList);
      keys.forEach((key) => {
        let fieldKeys = Object.keys(
          this.stepList[key][this.formKeys[key]].form
        );
        fieldKeys.forEach((field) => {
          this.stepList[key][this.formKeys[key]].form[field].value =
            payload[this.stepList[key][this.formKeys[key]].name][field];
        });
      });
    },
    handleChange(payload) {
      if (payload.address.cep != undefined && payload.address.cep.length > 0) {
        if (
          payload.address.cep.length === 9 &&
          payload.address.city.length === 0
        ) {
          this.$emit("checkCEP", payload.address.cep);
        }
      } else {
        let keys = Object.keys(this.stepList);
        keys.forEach((key) => {
          let fieldKeys = Object.keys(
            this.stepList[key][this.formKeys[key]].form
          );
          fieldKeys.forEach((field) => {
            this.stepList[key][this.formKeys[key]].form[field].value =
              payload[this.stepList[key][this.formKeys[key]].name][field];
          });
        });
      }
    },
    updateAddress(newAddress) {
      let addressKey = {};
      for (let addressFormKey in this.formKeys) {
        if (
          this.stepList[addressFormKey][this.formKeys[addressFormKey]].name ===
          "address"
        ) {
          addressKey = addressFormKey;
        }
      }

      if (Object.keys(newAddress).length > 0) {
        let addressKeys = Object.keys(newAddress);
        addressKeys.map((key) => {
          this.stepList[addressKey][this.formKeys[addressKey]].form[key].value =
            newAddress[key];
        });
      }
    },
    changeStep(payload) {
      this.step = payload;
    },
  },
  computed: {
    genre: function () {
      return this.$store.state.academy.currentAcademy.genre;
    },
    hasMembers: function () {
      return this.$store.state.user.users.length > 0;
    },
  },
  created() {
    // if (this.hasMembers && this.step === 1) {
    //   this.step = 2;
    // }
    if (Object.keys(this.addressQuery).length === 0) {
      Object.assign(this.addressQuery, this.blankAddress);
    }
    this.updateAddress(this.addressQuery);
  },
  mounted() {
    if (this.member != undefined) {
      this.populateForm(this.member);
    }
    this.stepList[2][2].form.graduation.items = getGraduationList(this.genre);
  },
  props: {
    addressQuery: {
      type: Object,
      default: () => {},
    },
    academyId: {
      type: String,
      default: "",
    },
    member: {
      type: Object,
      default: () => {},
    },
  },
  watch: {
    addressQuery: {
      // watch it
      handler: function (val) {
        this.updateAddress(val);
      },
      deep: true,
      immediate: true,
    },
  },
  data() {
    return {
      dialog: false,
      editMember: {},
      step: 1,
      blankAddress: {
        street: "",
        complement: "",
        neighborhood: "",
        city: "",
        state: "",
        cep: "",
      },
      stepList: JSON.parse(JSON.stringify(memberEditForm)),
      formKeys: {
        1: "3",
        2: "2",
        3: "2",
        4: "2",
        5: "2",
      },
    };
  },
};
</script>

<template>
  <section class="inner-form section-container">
    <the-inner-toolbar title="Editar Membro" :isForm="true" @cancel="cancel" />

    <app-stepper
      @changeStep="changeStep"
      :currentStep="step"
      :stepList="stepList"
      :formKeys="formKeys"
      @change="handleChange"
      @cancel="cancel"
      @submit="submit"
    />
    <app-dialog
      :dialog="dialog"
      title="Confirmação"
      text="Tem certeza que deseja cancelar? Os dados editados serão perdidos."
      buttonCancel="Voltar"
      buttonConfirm="Confirmar"
      @clickConfirm="cancelConfirm"
      @clickCancel="cancelCancel"
    />
  </section>
</template>

<style lang="scss" scoped>
.form-container,
form {
  min-height: 100vh;
  min-width: 100%;
  width: 100%;
}

.center-element {
  width: 100%;
  display: flex;
  justify-content: center;

  button {
    margin: 0 auto;
  }
}

span {
  width: 100%;
}
</style>
